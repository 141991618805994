/* Custom CSS for Okta Sign-In Widget */

@font-face {
    font-family: 'TTCommonsPro';
    src: url('/src/assets/fonts/TTCommonsProRegular.ttf') format('ttf'); /* Adjust path and format */
}

/* Change the background color of the entire widget */
#okta-sign-in {
    background-color: #ffffff !important;
    border-radius: 12px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    font-family: 'TTCommonsPro', 'montserrat-okta', 'sans-serif' !important;
}

#okta-sign-in .auth-container .main-container {
    font-family: 'TTCommonsPro', 'montserrat-okta', 'sans-serif' !important;
}

#okta-sign-in .o-form-button-bar {
    display: flex;
    justify-content: center;
    align-items: center;
}

#okta-sign-in.auth-container .button {
    width: 165px;
    height: 40px;
    border-radius: 100px;
    font-family: 'TTCommonsPro', 'montserrat-okta', 'sans-serif' !important;
}

#okta-sign-in.auth-container .button-primary {
    background-color: #3769ff; /* FT Theme Color */
    background: #3769ff; /* FT Theme Color */
    border-color: transparent;
    font-family: 'TTCommonsPro', 'montserrat-okta', 'sans-serif' !important;
}

#okta-sign-in.auth-container .button-primary:hover {
    background-color: #3769ff; /* FT Theme Color */
    background: #3769ff; /* FT Theme Color */
    border-color: transparent;
    font-family: 'TTCommonsPro', 'montserrat-okta', 'sans-serif' !important;
}

#okta-sign-in .auth-header {
    z-index: 10;
    border-bottom: none;
    padding: 60px 0 0 0;
    position: relative;
    transition: padding-bottom 0.4s;
    font-family: 'TTCommonsPro', 'montserrat-okta', 'sans-serif' !important;
}

#okta-sign-in.no-beacon .auth-content {
    padding-top: 5px;
}
#okta-sign-in .siw-main-header .beacon-container {
    display: none;
}
#okta-sign-in.no-beacon .auth-header {
    padding-bottom: 5px;
}
/* Hide the 'Unlock' Link from Widget */
a[data-se='unlock'] {
    display: none;
}

/* input[type='text'],
input[type='password'] {
    font-family: 'TTCommonsPro', 'montserrat-okta', 'sans-serif' !important;
} */
